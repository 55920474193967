import React, { useState, useContext } from "react"
import { FirebaseContext } from "../../firebase"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import { Link, navigate, graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import * as styles from "./form.module.scss"
import Spinner from "../UI/Spinner/Spinner"

import { checkValidity } from "../../shared/utility"

import googleLogo from "../../images/googleLogo.svg"
import appleLogo from "../../images/appleLogo.svg"

const cors = require('cors')({ origin: true });

const LoginForm = ({ location }) => {
  const microCopyTexts = useMicroCopyFi
  // UI texts from Contentful
  const labelErrorWrongCode = GetMicroCopy(microCopyTexts, "lomakeVääräKoodi")
  const labelErrorCodeUsed = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheKoodiKäytetty"
  )
  const labelErrorCodeRequired = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheKoodiVaaditaan"
  )
  const labelErrorFirstName = GetMicroCopy(microCopyTexts, "lomakeVirheEtunimi")
  const labelErrorLastName = GetMicroCopy(microCopyTexts, "lomakeVirheSukunimi")
  const labelErrorEmail = GetMicroCopy(microCopyTexts, "lomakeVirheSähköposti")
  const labelErrorPasswordLength = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSalasananPituus"
  )
  const labelErrorPassword = GetMicroCopy(microCopyTexts, "lomakeVirheSalasana")
  const labelErrorPasswordEmail = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSalasanaSähköposti"
  )
  const labelLoginFailed = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheKirjautuminenEpäonnistui"
  )
  const labelErrorEmailInUse = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSähköpostiKäytössä"
  )
  const labelErrorPasswordMatch = GetMicroCopy(
    microCopyTexts,
    "lomakeVirheSalasanaVahvista"
  )
  const labelLoginTitle = GetMicroCopy(microCopyTexts, "kirjautuminenOtsikko")
  const labelLoginText = GetMicroCopy(microCopyTexts, "kirjautuminenTeksti")
  const labelLoginLoadingText = GetMicroCopy(
    microCopyTexts,
    "kirjautuminenAvataanTeksti"
  )
  const labelCode = GetMicroCopy(microCopyTexts, "lomakeKoodi")
  const labelSubmit = GetMicroCopy(microCopyTexts, "lomakeLähetä")
  const labelEmail = GetMicroCopy(microCopyTexts, "lomakeSähköposti")
  const labelPassword = GetMicroCopy(microCopyTexts, "lomakeSalasana")
  const labelFirstname = GetMicroCopy(microCopyTexts, "lomakeEtunimi")
  const labelLastName = GetMicroCopy(microCopyTexts, "lomakeSukunimi")
  const labelPasswordConfirm = GetMicroCopy(
    microCopyTexts,
    "lomakeSalasanaVahvista"
  )
  const labelPhoneNumber = GetMicroCopy(microCopyTexts, "lomakePuhelinnumero")
  const labelLogin = GetMicroCopy(microCopyTexts, "lomakeKirjaudu")
  const labelLoginGoogle = GetMicroCopy(microCopyTexts, "lomakeKirjauduGoogle")
  const labelLoginApple = GetMicroCopy(microCopyTexts, "lomakeKirjauduApple")
  const labelPasswordLost = GetMicroCopy(
    microCopyTexts,
    "lomakeSalasanaUnohtunut"
  )
  const labelNoAccount = GetMicroCopy(microCopyTexts, "lomakeEiTiliä")
  const labelRegisterFree = GetMicroCopy(
    microCopyTexts,
    "lomakeRekisteröidyIlmaiseksi"
  )
  const labelRegisterTitle = GetMicroCopy(
    microCopyTexts,
    "lomakeRekisteröidyOtsikko"
  )
  const labelRegisterNewCode = GetMicroCopy(
    microCopyTexts,
    "lomakeRekisteröidyUusiKoodi"
  )
  const labelAcceptTerms = GetMicroCopy(
    microCopyTexts,
    "lomakeHyväksyKäsittely"
  )
  const labelOrderNewsletter = GetMicroCopy(microCopyTexts, "uutiskirjeTilaa")
  const labelProblems = GetMicroCopy(microCopyTexts, "lomakeOngelmia")
  // End UI texts

  const [loginFormValues, setLoginFormValues] = useState({
    email: "",
    password: "",
    code: "",
  })
  const [userCodeFormValues, setUserCodeFormValues] = useState({ code: "" })
  const [registrationFormValues, setRegistrationFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    code: "",
    termsAcceptance: false,
    newsletter: false,
  })

  const [used, setUsed] = useState(false)
  
  const [phase, setPhase] = useState(1)
  // Close enter code form for now and go straight to login form
  // const [phase, setPhase] = useState(0);
 

  const [loading, setLoading] = useState(false)

  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  
  function retryNewCode() {
    setUsed(false)
    setErrorMessage("")
    userCodeFormValues.code = ""
    setPhase(0)
  }

  function checkUserCode({ code }) {
    return firebase.checkUserCode({
      code: code,
    })
  }

  function checkRegisteredUserCode({ email, code }) {
    return firebase.checkRegisteredUserCode({
      email: email,
      code: code,
    })
  }

  function checkUsedUserCode({ code }) {
    return firebase.checkUsedUserCode({
      code: code,
    })
  }

  function saveUserLoginAction(email) {
    setLoading(true)
    firebase
      .addUserAction({ email: email, action: "login" })
      .then(ref => {
        if (location.state.returnUrl) {
          navigate(location.state.returnUrl)
        } else {
          navigate(`/virtuaalimessut`, { replace: false })
        }
      })
      .catch(err => {
        console.error("Error adding user action: ", err)
        setLoading(false)
      })
  }

  function addUser({
    email,
    first_name,
    last_name,
    terms_and_conditions_acceptance,
    newsletter,
  }) {
    return firebase.addUser({
      email: email,
      first_name: first_name,
      last_name: last_name,
      terms_and_conditions_acceptance: terms_and_conditions_acceptance,
      newsletter: newsletter,
    })
  }

  function addUserPhone({ email, phone_number }) {
    return firebase.addUserPhone({
      email: email,
      phone_number: phone_number,
    })
  }

  function addRegisteredUserCode({ email, code }) {
    return firebase.addRegisteredUserCode({
      email: email,
      code: code,
    })
  }

  function handleSubmitUserCode(e) {
    e.preventDefault()
    setLoading(true)
    checkUserCode({ code: userCodeFormValues.code })
      .then(snapshot => {
        if (snapshot["data"]["_size"] > 0) {
          setPhase(1)
        } else {
          setErrorMessage(labelErrorWrongCode)
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(labelErrorWrongCode)
        setLoading(false)
      })
  }

  function showRegistrationForm() {
    setPhase(2)
  }

  function handleRegistrationInputChange(e) {
    e.persist()
    setErrorMessage("")
    setRegistrationFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]:
        e.target.name === "termsAcceptance" || e.target.name === "newsletter"
          ? e.target.checked
          : e.target.value,
    }))

    switch (e.target.name) {
      case "firstName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorFirstName)
        } else {
          setErrorMessage("")
        }
        break
      case "lastName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorLastName)
        } else {
          setErrorMessage("")
        }
        break
      case "email":
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(labelErrorEmail)
        } else {
          setErrorMessage("")
        }
        break
      case "password":
        if (!checkValidity(e.target.value, { required: true, minLength: 8 })) {
          setErrorMessage(labelErrorPasswordLength)
        } else {
          setErrorMessage("")
        }
        break
      default:
        break
    }
  }

  function handleSubmitLogin(e) {
    e.preventDefault()
    setLoading(true)
    firebase
      .login({
        email: loginFormValues.email,
        password: loginFormValues.password,
      })
      .then(function () {
        // Close enter code form for now and go straight to login form
        /* */
        checkRegisteredUserCode({ email: loginFormValues.email, code: loginFormValues.code }).then((snapshot) => {
          if (snapshot['data']['_size'] > 0) {
            saveUserLoginAction(loginFormValues.email);
          } else {
            setErrorMessage(labelErrorWrongCode);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setErrorMessage(labelErrorPasswordEmail);
          setLoading(false);
        });
        /* */
        saveUserLoginAction(loginFormValues.email)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(labelErrorPassword)
        setLoading(false)
      })
  }

  function handelInputChangeUserCode(e) {
    e.persist()
    setErrorMessage("")
    setUserCodeFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))

    switch (e.target.name) {
      case "code":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorCodeRequired)
        } else {
          //console.log(e.target.value)
          setLoginFormValues({
            email: "",
            password: "",
            code: e.target.value,
          })
          setRegistrationFormValues({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            code: e.target.value,
            termsAcceptance: false,
            newsletter: false,
          })
          setErrorMessage("")
        }
        break
      default:
        break
    }
  }

  function handelInputChangeLogin(e) {
    e.persist()
    setErrorMessage("")
    setLoginFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))

    switch (e.target.name) {
      case "email":
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(labelErrorEmail)
        } else {
          setErrorMessage("")
        }
        break
      case "password":
        if (!checkValidity(e.target.value, { required: true, minLength: 8 })) {
          setErrorMessage(labelErrorPasswordLength)
        } else {
          setErrorMessage("")
        }
        break
      default:
        break
    }
  }

  function handleSubmitRegistration(e) {
    e.preventDefault()
    setLoading(true)
    if (
      registrationFormValues.password === registrationFormValues.confirmPassword
    ) {
      // Open check code and go registration
      /* */
      checkUsedUserCode({ code: registrationFormValues.code }).then((snapshot) => {
        if (snapshot['data']['_size'] > 0) {
          setErrorMessage(labelErrorCodeUsed);
          setLoading(false);
          setUsed(true);
        }
        else {
          firebase.register({
            email: registrationFormValues.email,
            password: registrationFormValues.password
          })
          .then(ref => {
            addUser({
              'email': registrationFormValues.email,
              'first_name': registrationFormValues.firstName,
              'last_name': registrationFormValues.lastName,
              'terms_and_conditions_acceptance': registrationFormValues.termsAcceptance,
              'newsletter': registrationFormValues.newsletter
            }).then(ref => {
              addRegisteredUserCode({
                'email': registrationFormValues.email,
                'code': registrationFormValues.code
              }).then(ref => {
                firebase.login({ email: registrationFormValues.email, password: registrationFormValues.password })
                .then(function () {
                  saveUserLoginAction(registrationFormValues.email);
                })
                .catch(error => {
                  console.log(error);
                  setErrorMessage(labelLoginFailed);
                  setLoading(false);
                });
              });
            });
          }).catch(err => {
            setErrorMessage(labelErrorEmailInUse);
            setLoading(false);
          });
        }
      });
      // */
      firebase
        .register({
          email: registrationFormValues.email,
          password: registrationFormValues.password,
        })
        .then(ref => {
          addUser({
            email: registrationFormValues.email,
            first_name: registrationFormValues.firstName,
            last_name: registrationFormValues.lastName,
            terms_and_conditions_acceptance:
              registrationFormValues.termsAcceptance,
            newsletter: registrationFormValues.newsletter,
          }).then(ref => {
            addUserPhone({
              email: registrationFormValues.email,
              phone_number: registrationFormValues.phoneNumber,
            }).then(refUserPhone => {
              firebase
                .login({
                  email: registrationFormValues.email,
                  password: registrationFormValues.password,
                })
                .then(function () {
                  saveUserLoginAction(registrationFormValues.email)
                })
                .catch(error => {
                  setErrorMessage(labelLoginFailed)
                  setLoading(false)
                })
            })
          })
        })
        .catch(err => {
          setErrorMessage(labelErrorEmailInUse)
          setLoading(false)
        })
    } else {
      setErrorMessage(labelErrorPasswordMatch)
      setLoading(false)
    }
  }

  function authViaGoogle() {
    firebase.authViaGoogle(saveUserLoginAction)
  }

  function authViaApple() {
    firebase.authViaApple(saveUserLoginAction)
  }

  return (
    <Box
      component="section"
      className={styles.form}
      style={{ padding: "0 20px", maxWidth: "1240px", margin: "85px auto" }}
    >
      <Box component="div">
        {phase === 0 && !loading && (
          <header>
            <h1>{labelLoginTitle}</h1>
            <p>{labelLoginText}</p>
          </header>
        )}

        {phase === 1 && !loading && (
          <header>
            <h1>{labelLoginTitle}</h1>
            <p>{labelLoginText}</p>
          </header>
        )}

        {phase === 1 && loading && (
          <header>
            <p>{labelLoginLoadingText}</p>
          </header>
        )}

        <Box component="div" className={styles.formContainer}>
          {loading && <Spinner />}

          {phase === 0 && !loading && (
            <form onSubmit={handleSubmitUserCode}>
              <div className={styles.formRow}>
                <label>
                  {labelCode} <span>*</span>
                </label>
                <input
                  value={userCodeFormValues.code}
                  name="code"
                  onChange={handelInputChangeUserCode}
                  type="text"
                  required
                ></input>
              </div>

              <div className={styles.formRow}>
                {!!errorMessage && (
                  <p className={styles.errorMessage}>{errorMessage}</p>
                )}
              </div>

              <button type="submit">{labelSubmit}</button>
            </form>
          )}

          {phase === 1 && !loading && (
            <form onSubmit={handleSubmitLogin}>
              <input
                value={loginFormValues.user_code}
                value={loginFormValues.code}
                name="loginUserCode"
                type="hidden"
                disabled
              ></input>

              <div className={styles.formRow}>
                <label>
                  {labelEmail} <span>*</span>
                </label>
                <input
                  value={loginFormValues.email}
                  name="email"
                  onChange={handelInputChangeLogin}
                  type="email"
                  required
                ></input>
              </div>

              <div className={styles.formRow}>
                <label>
                  {labelPassword} <span>*</span>
                </label>
                <input
                  value={loginFormValues.password}
                  name="password"
                  onChange={handelInputChangeLogin}
                  type="password"
                  required
                ></input>
              </div>

              <div className={styles.formRow}>
                {!!errorMessage && (
                  <p className={styles.errorMessage}>{errorMessage}</p>
                )}
              </div>

              <button style={{ marginBottom: "25px" }} type="submit">
                {labelLogin}
              </button>
              

              <div className={styles.formRow}>
                <button
                  className={styles.buttonOutlined}
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={authViaGoogle}
                >
                  <img src={googleLogo} alt="" /> {labelLoginGoogle}{" "}
                </button>
              </div>
              
              <div className={styles.formRow}>
                <button
                  className={styles.buttonOutlined}
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={authViaApple}
                >
                  <img src={appleLogo} alt="" /> {labelLoginApple}{" "}
                </button>
              </div>


              <p className={styles.formNotifications}>
                <Link to={"/lost-password"}>{labelPasswordLost}</Link>
              </p>

              <footer>
                <p>{labelNoAccount}</p>
                <button type="button" onClick={showRegistrationForm}>
                  {labelRegisterFree}
                </button>
              </footer>
            </form>
          )}

          {phase === 2 && !loading && (
            <Box component="div" className={styles.formContainer}>
              <header>
                <h1>{labelRegisterTitle}</h1>
              </header>
              <form onSubmit={handleSubmitRegistration}>
                {!!errorMessage && (
                  <p className={styles.errorMessage}>{errorMessage}</p>
                )}

                {used && !loading && (
                  <p className={styles.alignCenter}>
                    <span onClick={retryNewCode} className={styles.link}>
                      {labelRegisterNewCode}
                    </span>
                  </p>
                )}
                <input
                  value={registrationFormValues.user_code}
                  value={registrationFormValues.code}
                  name="registrationUserCode"
                  type="hidden"
                  disabled
                ></input>

                <div className={styles.formRow}>
                  <label>
                    {labelFirstname} <span>*</span>
                  </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.firstName}
                    type="text"
                    required
                    name="firstName"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelLastName} <span>*</span>
                  </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.lastName}
                    type="text"
                    required
                    name="lastName"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelEmail} <span>*</span>
                  </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.email}
                    type="email"
                    required
                    name="email"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelPassword} <span>*</span>
                  </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.password}
                    type="password"
                    required
                    minLength={8}
                    name="password"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelPasswordConfirm} <span>*</span>
                  </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.confirmPassword}
                    type="password"
                    required
                    name="confirmPassword"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>{labelPhoneNumber} </label>
                  <input
                    onChange={handleRegistrationInputChange}
                    value={registrationFormValues.phoneNumber}
                    type="text"
                    name="phoneNumber"
                  />
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelAcceptTerms} <span>* </span>
                    <input
                      onChange={handleRegistrationInputChange}
                      value={registrationFormValues.termsAcceptance}
                      type="checkbox"
                      required
                      name="termsAcceptance"
                    />
                  </label>
                </div>

                <div className={styles.formRow}>
                  <label>
                    {labelOrderNewsletter} <span> </span>
                    <input
                      onChange={handleRegistrationInputChange}
                      value={registrationFormValues.newsletter}
                      type="checkbox"
                      name="newsletter"
                    />
                  </label>
                </div>

                <button type="submit">{labelRegisterFree}</button>
              </form>

              <footer>
                <p>
                  {labelProblems}{" "}
                  <a href="mailto:virtuaali@asuntomessut.fi.">
                    virtuaali@asuntomessut.fi.
                  </a>
                </p>
              </footer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default LoginForm
